// import {greeting} from './greeting'
import '.././styles/main.scss'
import Vue from 'vue/dist/vue.min'
import Vuelidate from 'vuelidate'
import images from '../images/*.jpg'
import smoothscroll from 'smoothscroll-polyfill'
Vue.use(Vuelidate)
import {
    requiredIf
} from 'vuelidate/lib/validators'

//safari polyfill
smoothscroll.polyfill()

//incoming data
const queryString = window.location.search
// console.log(queryString);
const urlParams = new URLSearchParams(queryString)
// console.log(urlParams);
var session_id = urlParams.get('session_id')
// console.log(session_id);

Vue.filter('toCurrency', function (value) {
    // return value
    return parseFloat(value).toFixed(2)
})

//config
const testMode = 0

if(testMode) {
    var enginePath = 'http://localhost:8888'
} else {
    var enginePath = 'https://beta.retailzoo.com.au/engine'
}

var getStockCount = function() {
    
    fetch(enginePath+'/stocks-count.php', {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => response.json())
      .then(data => {
        if(!session_id) {
            app.messageLoader = ''
        }
        app.items = data
      })
      .catch((error) => {
        console.error('Error:', error)
        app.messageLoader = ''
      });
}
getStockCount()

var enableInputs = null

var errorHandler = function (error, timer) {

    app.errorMessage = error
    setTimeout(() => {
        app.errorMessage = ''
    }, timer)

    setTimeout(() => {
        document.getElementById("userErrors").scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }, 500)

    enableInputs()

}

var app = new Vue({
    el: '#giftCards',
    data: {
        finalCost: 10,
        template1: images.card_01,
        template2: images.card_02,
        template3: images.card_03,
        template: images.card_01,
        stepTwo: false,
        messageLoader: '<h3>Finalising your order!</h3> Please hold on...',
        successScreen: false,
        errorMessage: '',
        submitStatus: null,
        payLock: false,
        hash: '',
        key: '',
        pdfLink: '',
        pdfLinkText: 'Download proof of purchase',
        proceedButton: 'Proceed to payment',
        payId: '',
        myselect: '',
        selected: '',
        items: []
    },
    validations: {
        // finalCost: {
        //     required,
        //     between: between(20, 250)
        // },
        // fullName: {
        //     required
        // },
        // email: {
        //     required,
        //     email
        // },
        // emailConfirm: {
        //     sameAsEmail: sameAs('email')
        // },
        // myselect: {
        //     required
        // },
        selected: {
            required: requiredIf(function (form) {
                return !form.selected.count
            })
        },
        // emailRec: {
        //     required: requiredIf(function (form) {
        //         return form.giftCard
        //     }),
        //     email
        // },
        // emailRecConfirm: {
        //     required: requiredIf(function (form) {
        //         return form.giftCard
        //     }),
        //     sameAsRecEmail: sameAs('emailRec')
        // },
    },
    computed: {
        charactersLeft() {
            var count = this.message.length
            return count
        }
    },
    methods: {
        onChange() {
            if (!app.$v.$invalid) {
                app.submitStatus = 'OK'
            }
        },
        submit() {

            this.$v.$touch()
            // console.log(this.$v.$invalid)
            if (this.$v.$invalid) {

                this.submitStatus = 'ERROR'
                console.log('ERROR')

            } else {

                app.proceedButton = 'Please wait...'
                var stripe = Stripe('pk_test_51H3Yv2C1JL5EwQPA9bcXs7gr6CQsxzXzLTJ08F31ay7dUdVhmKm6wW7ieEPnLwXmIbprMFKwAc05bFKgMxcDDSRP00ZIrfGjVP')

                fetch(enginePath+'/session.php', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "payload": app.selected
                    })
                })
                .then(function (response) {
                    return response.json()
                })
                .then(function (session) {

                    if(session.id) {
                        return stripe.redirectToCheckout({
                            sessionId: session.id
                        })
                    } else {
                        errorHandler("Someting went wrong. Please try again.", 8000)
                    }
                    
                })
                .then(function (result) {

                    console.log(result)
                    errorHandler("Someting went wrong. Please try again.", 8000)

                })
                .catch(function (error) {

                    console.log(error)
                    errorHandler("Someting went wrong. Please try again.", 8000)

                })

            }

        },
        templateSwitch(template) {
            this.template = template
        },
        resetStates() {

            if (testMode) {

                this.stepTwo = false
                this.successScreen = false
                this.hash = ''
                this.key = ''

            } else {

                this.finalCost = 10
                this.template = images.card_01
                this.stepTwo = false
                this.messageLoader = ''
                this.successScreen = false
                this.errorMessage = ''
                this.submitStatus = null
                this.payLock = false
                this.hash = ''
                this.key = ''
                this.pdfLink = '',
                this.pdfLinkText = 'Download proof of purchase'

            }

            session_id = null
            app.messageLoader = '<h3>Finalising your order!</h3> Please hold on...'
            getStockCount()

        },
        downloadPDF() {

            this.pdfLinkText = 'Downloading now...'

        }
    }

})

if (session_id) {

    app.messageLoader = '<h3>Finalising your order!</h3> Please hold on...'
    app.payId = session_id

    fetch(enginePath+'/success.php', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "payId": app.payId
        })
    })
    .then(function (response) {
        return response.json()
    })
    .then(function (response) {

        if(response.success) {

            app.messageLoader = ''
            app.successScreen = true
            app.pdfLink = response.PDF

        } else {

            errorHandler(response.message, 8000)

        }
        
    })
    .then(function (result) {

        console.log(result);
        errorHandler("Someting went wrong. Please try again.", 8000)

    })
    .catch(function (error) {

        console.log(error);
        errorHandler("Someting went wrong. Please try again.", 8000)

    })

}

//mock
if (testMode) {
    app.fullName = 'Retail Zoo'
    app.email = 'asanga@gmail.com'
    app.emailConfirm = 'asanga@gmail.com'
    app.nameRec = 'Asanga Dev'
    app.emailRec = 'asanga.dev@gmail.com'
    app.emailRecConfirm = 'asanga.dev@gmail.com'
    app.message = 'Hi Jess, wishing you the most amazing birthday! Enjoy it at your favourite place, Betty’s Burgers!'
}